import React from 'react';
import {
   Slide,
   Box,
   TextField,
   Button,
   Dialog,
   DialogTitle,
   DialogContent,
   Collapse,
   DialogActions,
   FormControlLabel,
   Checkbox,
   CircularProgress,
   IconButton,
} from '@mui/material';
import $ from 'jquery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet'
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import Cookies from 'universal-cookie';

export function MainPage(props) {
   const showOnMouseOver = false;
   const theme = useTheme();
   const refForm = React.useRef();
   const refIframe = React.useRef();
   const cookies = React.useMemo(() => new Cookies(), []);
   const [config, setConfig] = React.useState(null);
   const [failure, setFailure] = React.useState(null);
   const [showPanel, setShowPanel] = React.useState(false);
   const [title, setTitle] = React.useState("...");
   const [iframeURL, setIframeURL] = React.useState(null);

   React.useEffect(() => {
      const redirect = new URL(document.URL).searchParams.get('redirect');

      if( redirect ) {
         setIframeURL( decodeURIComponent(redirect) );
      }

      $.ajax({
         url: '/config',
         type: 'get',
         dataType: 'json',
      }).done(function(ajax) {
         setConfig(ajax);
         setIframeURL( iframeURL => iframeURL ?? ajax?.start);
      }).fail(function(jqXHR) {
         setFailure({ message: 'Ошибка получения списка систем.', ...jqXHR.responseText ? { error: jqXHR.responseText } : {}});
      });
      setInterval(() => setTitle(refIframe.current?.contentDocument?.title ?? "..."), 1000)

      function hidePanel(event) {
         if( event.key === 'Escape' )
            setShowPanel(false);
      }

      document.addEventListener('keyup', hidePanel);

      return () => document.removeEventListener('keyup', hidePanel);
   }, []);

   const url = React.useMemo(() => {
      if( config?.sid ) {
         const url = new URL(document.URL);
         url.pathname = url.pathname.replace('/frontend/index.html', '/');
         url.searchParams.set(config.sid, cookies.get(config.sid) ?? "???")
         return `${url}`;
      } else {
         return null;
      }
   }, [config, cookies]);

   function handleMouseOut(event) {
      if( !$(event.relatedTarget).parents().is(event.target) )
         setShowPanel(false);
   }

   return failure ? (
      <>
         {config?.css && (
            <Helmet>
               <link href={config.css} rel="stylesheet"/>
            </Helmet>
         )}
         <Dialog
            open={true}
            className={"failure"}
         >
            <DialogTitle>{failure.message}</DialogTitle>
            <DialogContent>
            {failure?.error && (
               <Box>
                  <FormControlLabel
                     control={<Checkbox checked={failure.detail === true}/>}
                     label="Детализация ошибки"
                     onClick={event => setFailure({...failure, detail: event.target.checked})}
                  />
                  <Collapse
                     in={failure.detail === true}
                     timeout={1000}
                  >
                     <Box sx={{ whiteSpace: 'pre' }}>
                        {parse(DOMPurify.sanitize(failure.error, {
                           USE_PROFILES: { html: true },
                        }))}
                     </Box>
                  </Collapse>
               </Box>
            )}
            {failure?.buttons && (
               <DialogActions>
               {failure.buttons.map((button, index) => (
                  <Button
                     key={index}
                     {...button}
                  >
                     {button.label}
                  </Button>
               ))}
               </DialogActions>
            )}
            </DialogContent>
         </Dialog>
      </>
   ) : url ? (
      <>
         {config?.css && (
            <Helmet>
               <link href={config.css} rel="stylesheet"/>
            </Helmet>
         )}
         <Slide
            in={!showPanel}
            direction="down"
            unmountOnExit
         >
            <Box
               className="panel"
               sx={{
                  height: 10,
                  width: 100,
                  position: 'fixed',
                  top: 0,
                  left: 'calc(50vw - 50px)',
                  zIndex: 2,
               }}
               onMouseOver={showOnMouseOver ? (event) => setShowPanel(true) : null}
               onClick={showOnMouseOver ? null : (event) => setShowPanel(true)}
            >
               <Box
                  className="inner"
                  sx={{
                     backgroundColor: 'white',
                     flexGrow: 1,
                     height: '100%',
                     borderRadius: '0 0 10px 10px',
                     transition: 'box-shadow 0.5s',
                     boxShadow: theme.shadows[3],
                     '&:hover': {
                        boxShadow: theme.shadows[10],
                     },
                     ...(showOnMouseOver ? {} : {
                        cursor: "pointer",
                     }),
                  }}
               />
            </Box>
         </Slide>
         <Slide
            in={showPanel}
            direction="down"
            unmountOnExit
         >
            <Box
               ref={refForm}
               className="form"
               sx={{
                  p: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: theme.shadows[5],
                  zIndex: 1,
               }}
               onMouseOut={showOnMouseOver ? (event) => event.target === refForm.current && handleMouseOut(event) : null}
            >
               <IconButton
                  onClick={(event) => setShowPanel(false)}
               >
                  <CloseIcon/>
               </IconButton>
               <Box sx={{ flexGrow: 1 }} />
               <TextField
                  label={config?.['link-label'] ?? 'Link'}
                  defaultValue={url}
                  InputProps={{
                     readOnly: true,
                  }}
                  sx={{ width: 500 }}
                  autoFocus={true}
               />

               <CopyToClipboard text={url}>
                  <Button
                     variant="contained"
                     color="inherit"
                     sx={{
                        m: 2,
                     }}
                  >
                     Скопировать
                  </Button>
               </CopyToClipboard>
               <Box sx={{ flexGrow: 1 }} />
               <Button href={`mailto:?subject=${encodeURIComponent(config?.['email-subject'] ?? config?.['link-label'] ?? 'Link')}&body=${encodeURIComponent(url)}`}>Отправить по e-mail</Button>
            </Box>
         </Slide>
         <Helmet>
            <title>{title}</title>
         </Helmet>
         <iframe
            ref={refIframe}
            title="Contents..."
            style={{
               position: "fixed",
               width: "100vw",
               height: "100vh",
               minHeight: "-webkit-fill-available",
               maxHeight: "-webkit-fill-available",
               left: 0,
               top: 0,
               zIndex: -1000,
               border: 0,
            }}
            src={iframeURL ?? "about:blank"}
            referrerPolicy="same-origin"
         >
         </iframe>
      </>
   ) : (
      <>
         {config?.css && (
            <Helmet>
               <link href={config.css} rel="stylesheet"/>
            </Helmet>
         )}
         <CircularProgress/>
      </>
   )
}